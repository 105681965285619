import { Injectable } from '@angular/core';
import { IApplicationContextService } from './iApplication-context.service';
import { CompanyName } from '../models/models';
// import { ICompanyService } from './iCompany.service';
import { Observable, Subject, of, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

//TODO:rewrite this class. mix of static methods. multiple set styles etc.
export class ApplicationContextService extends IApplicationContextService {

  private static _companyContext: CompanyName = null;
  private static _requestsModified = false;
  private static _companiesModified = false;
  private static _userDetailsModified = false;
  private static _showAppSpinner = false;
  private static _showLongRunning = false;
  private static _showRequestsBar = false;

  get companyContext() {
    return ApplicationContextService._companyContext;
  }
  set companyContext(val: CompanyName) {
    ApplicationContextService._companyContext = val;
    this.companyContextObserver.next(ApplicationContextService._companyContext);
  }
  public companyContextObserver = new ReplaySubject<CompanyName>(1);

  get requestsModified() {
    return ApplicationContextService._requestsModified;
  }
  set requestsModifed(val: boolean) {
    ApplicationContextService._requestsModified = val;
    this.requestsModifiedObserver.next(ApplicationContextService._requestsModified);
  }
  public requestsModifiedObserver = new Subject<boolean>();

  get companiesListModified() {
    return ApplicationContextService._companiesModified;
  }
  set companiesListModifed(val: boolean) {
    ApplicationContextService._companiesModified = val;
    this.companiesListModifiedObserver.next(ApplicationContextService._companiesModified);
  }
  public companiesListModifiedObserver = new Subject<boolean>();

  get userDetailsModified() {
    return ApplicationContextService._userDetailsModified;
  }
  set userDetailsModifed(val: boolean) {
    ApplicationContextService._userDetailsModified = val;
    this.userDetailsModifiedObserver.next(ApplicationContextService._userDetailsModified);
  }
  public userDetailsModifiedObserver = new Subject<boolean>();

  get showAppSpinner() {
    return ApplicationContextService._showAppSpinner;
  }
  set showAppSpinner(val: boolean) {
    ApplicationContextService._showAppSpinner = val;
    this.showApplicationSpinnerObserver.next(ApplicationContextService._showAppSpinner);
  }
  public showApplicationSpinnerObserver = new Subject<boolean>();

  get showLongRunning() {
    return ApplicationContextService._showLongRunning;
  }
  set showLongRunning(val: boolean) {
    ApplicationContextService._showLongRunning = val;
    this.showLongRunningObserver.next(ApplicationContextService._showLongRunning);
  }
  public showLongRunningObserver = new Subject<boolean>();

  get showRequestsBar() {
    return ApplicationContextService._showRequestsBar;
  }
  set showRequestsBar(val: boolean) {
    ApplicationContextService._showRequestsBar = val;
    this.showRequestsBarObserver.next(ApplicationContextService._showRequestsBar);
  }
  public showRequestsBarObserver = new Subject<boolean>();

  constructor() {
    super();
  }

  getCompanyContext(): CompanyName {
    return this.companyContext;
  }

  setCompanyContext(companyName: CompanyName): void {
    this.companyContext = companyName;
  }

  
  alertRequestsUpdated(): void {
    this.requestsModifed = true;
  }

  acknowledgeRequestsUpdated(): void {
    this.requestsModifed = false;
  }

  alertCompaniesListUpdated(): void {
    this.companiesListModifed = true;
  }

  acknowledgeCompaniesListUpdated(): void {
    this.companiesListModifed = false;
  }

  alertUserDetailsUpdated(): void {
    this.userDetailsModifed = true;
  }

  acknowledgeUserDetailsUpdated(): void {
    this.userDetailsModifed = false;
  }

  showApplicationSpinner(isLongRunning: boolean = false): void {
    if (isLongRunning) {
      this.showLongRunning = true;
    }
    this.showAppSpinner = true;
  }

  hideApplicationSpinner(): void {
    this.showLongRunning = false;
    this.showAppSpinner = false;
  }

  showApplicationRequestsBar(): void {
    this.showRequestsBar = true;
  }

  hideApplicationRequestsBar(): void {
    this.showRequestsBar = false;
  }
}
