import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { KeyValuePairOfStringAndNullableDouble, VendorJob } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-job-fluid-summary',
  standalone: false,
  templateUrl: './job-fluid-summary.component.html',
  styleUrls: ['./job-fluid-summary.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JobFluidSummaryComponent),
    multi: true
  }]
})
export class JobFluidSummaryComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;
  public isDisabled: boolean;

  @Input() _job: VendorJob;
  get job() {
    return this._job;
  }
  set job(val) {
    this._job = val;
    this.propagateChange(this._job);
  }

  availableChemicals: SelectItem[] = [];

  constructor() { }

  ngOnInit() {
    this.setupDropDownOptions();
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => { };

  writeValue(obj: any): void {
    if (obj) {
      this.job = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /********* UI Methods *********/
  setupDropDownOptions() {
    this.availableChemicals = [
      { label: 'Blue Dye 3102', value: 'Blue Dye 3102' },
      { label: 'Nano iPop 5102', value: 'Nano iPop 5102' },
      { label: 'ProScav', value: 'ProScav' },
      { label: 'Red Dye 3101', value: 'Red Dye 3101' },
      { label: 'iFR8320', value: 'iFR8320' },
      { label: 'iLube 5103', value: 'iLube 5103' }
    ];
  }

  addChemical() {
    let chemicalIndex = 0;
    if (!this.job.fluidSummary.chemicals) {
      this.job.fluidSummary.chemicals = [];
    }
    if (this.job.fluidSummary.chemicals.length > 0) {
      chemicalIndex = this.job.fluidSummary.chemicals.length - 1;
    }
    this.job.fluidSummary.chemicals.push( KeyValuePairOfStringAndNullableDouble.fromJS({ key: '', value: 0.0 }));
  }

  deleteChemical(index: number) {
    if (this.job.fluidSummary.chemicals && index >= 0 && index < this.job.fluidSummary.chemicals.length) {
      this.job.fluidSummary.chemicals.splice(index, 1);
      // Trigger change detection
      this.job = VendorJob.fromJS(this.job);
    }
  }
}
