import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {
  IOpsViewerMessagesService,
  IUserService,
  ICompanyService,
  IApplicationContextService
} from 'src/app/services/services';
import { BehaviorSubject, combineLatest, firstValueFrom, from, map, Observable, shareReplay, Subscription, switchMap, tap } from 'rxjs';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { ConsumerJob, DeviceClient, IConsumerJobClient, IVendorJobClient, User, VendorJob } from 'src/app/services/nswag/nswagclient';
import { JobCacheService } from 'src/app/services/job-cache.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilePopUpComponent } from '../../files/file-pop-up/file-pop-up.component';
import { CompanyName } from 'src/app/models/companyName';
import { TelemetryService } from 'src/app/services/telemetry.service';

@Component({
  selector: 'app-vendor-consumer-jobs',
  standalone: false,
  templateUrl: './vendor-consumer-jobs.component.html',
  styleUrls: ['./vendor-consumer-jobs.component.scss']
})
export class VendorConsumerJobsComponent implements OnInit, OnDestroy {

  
  vendorJobCols: any[] = [];
  //loadingVendorJobs = false;
  //vendorJobsReady = false;
  //vendorJobs: VendorJob[] = [];
  //vendorDevices: Device[] = [];

   consumerJobCols: any[] = [];
  // consumerJobsReady = false;
  // consumerJobs: ConsumerJob[] = [];

  // vendorCompaniesReady = false;
  // vendorCompanies: SelectItem[] = [];

  // consumerCompaniesReady = false;
  // consumerCompanies: SelectItem[] = [];

  //userReady = false;
  //user: User;
  //userCompanyId: string;
  isUserCompanyVendor = false;
  isUserCompanyConsumer = false;

  // vendorTableLoading = true;
  // consumerTableLoading = true;

  securityToken: string;
  ref: DynamicDialogRef | undefined;
  //private _companyContextSubscription: Subscription;

  constructor(
    @Inject("IVendorJobClient") private _vendorJobService: IVendorJobClient,
    @Inject("IConsumerJobClient") private _consumerJobService: IConsumerJobClient,
    private _userService: IUserService,
    private _companyService: ICompanyService,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService,
    private _userManagerService: IUserManagerService,
    private _titleService: Title,
    private _router: Router,
    private _confirmationService: ConfirmationService,
    //private _deviceService: IDeviceService,
    private _deviceClient: DeviceClient,
    private _currentUserService: ICurrentUserService,
    private _jobsCacheService: JobCacheService,
    private _dialogService: DialogService,
    private _telemetryService: TelemetryService
  ) { }

  company$: Observable<CompanyName>;
  vendorCompanies$: Observable<CompanyName[]>;
  user$: Observable<User>;
  vendorJobs$: Observable<VendorJob[]>;
  consumerJobs$: Observable<ConsumerJob[]>;
  isUserCompanyVendor$: Observable<boolean>;
  isUserCompanyConsumer$: Observable<boolean>;
  roles$: Observable<UserRoles>;
  applicationBarSubscription: Subscription;
  deletedId$= new BehaviorSubject<string[]>([]);
  ngOnInit() {

    this.user$ = this._userManagerService.getCurrentUser();

    this.company$ = this._applicationContextService.companyContextObserver.pipe(tap(c => {
      if (!c) {
        this._telemetryService.trackTrace({message: 'Waring!: Company Context Changed to null. This can only happen if they are not assigned to a company.'});
      }
      //console.log('Company Context Changed', c);
    }));
    this.vendorCompanies$ = this._companyService.getVendorCompanyNames().pipe(shareReplay(1));
    const consumerJobs$ = from(this._jobsCacheService.getConsumerJobs());
    const consumerCompanies$ = this._companyService.getConsumerCompanyNames().pipe(shareReplay(1));

    const devices$ = this.company$.pipe(switchMap(c => this._deviceClient.getDevicesForCompanyIncludingDeleted(c.companyId)), shareReplay(1));

    const vendorJobs$ = this.company$.pipe(switchMap(c => from(this._jobsCacheService.getVendorJobsForVendorCompany(c.companyId))), shareReplay(1));

    const vendorCompaniesAndJobsAndDevices$ = combineLatest([consumerCompanies$, vendorJobs$, devices$, this.deletedId$]);

    const agumentedVendorJobs$ = vendorCompaniesAndJobsAndDevices$.pipe(map(([consumerCompanies, jobs, devices, deleted]) => {
      var filteredJobs = jobs.filter(j => !deleted.includes(j.id));
      for (const j of filteredJobs) {
        let deviceNames = '';
        const company = consumerCompanies.find(c => c.companyId === j.consumerCompanyId);
        if (company) {
          (j as any).consumerCompanyName = company.companyName;
        }
        j.deviceList.map(id => {
          const foundDeviceName = devices.find(d => d.id === id);
          if (foundDeviceName) {
            deviceNames = deviceNames.length > 0 ? deviceNames.concat(', ' + foundDeviceName.name) : deviceNames.concat(foundDeviceName.name);
          }
        });
        (j as any).deviceNamesAsString = deviceNames;
      }
      filteredJobs.sort((a, b) => {
        if (a.startTime > b.startTime) {
          return -1;
        } else if (a.startTime < b.startTime) {
          return 1;
        }
        return 0;
      });
      return filteredJobs;
    }));
    this.vendorJobs$ = agumentedVendorJobs$;


    const consumerJobsAndCompanies$ = combineLatest([consumerJobs$, consumerCompanies$, this.vendorCompanies$, this.company$]);

    const agumentedConsumerJobs = consumerJobsAndCompanies$.pipe(map(([jobs, consumerCompanies, vendorCompanies, currentCompany]) => {
      const results = jobs;//.map(j => Object.assign(new ConsumerJob(), j) as ConsumerJob);
      for (const j of results) {
        const company = consumerCompanies.find(c => c.companyId === j.consumerCompanyId);
        if (company) {
          (j as any).consumerCompanyName = company.companyName;
        }
        // Get VendorCompany Names
        if (j.vendorCompanyIds) {
          const foundVendorCompanies = vendorCompanies.filter(v => j.vendorCompanyIds.includes(v.companyId));
          if (foundVendorCompanies) {
            (j as any).vendorCompanyNames = foundVendorCompanies.map(v => v.companyName).join(',');
          }
        }
      }

      const visibleJobs  = results.filter(j =>
        j.consumerCompanyId === currentCompany.companyId || j.vendorCompanyIds.includes(currentCompany.companyId)
      );

      visibleJobs.sort((a, b) => {
        if (a.startTime > b.startTime) {
          return -1;
        } else if (a.startTime < b.startTime) {
          return 1;
        }
        return 0;
      });
      return visibleJobs;

    }
    ));
    this.consumerJobs$ = agumentedConsumerJobs;
    this.isUserCompanyVendor$ = combineLatest([this.company$, this.vendorCompanies$]).pipe(map(([company, companies]) => {
      if (!company) { //no company context
        return false;
      }
      return companies.find(c => c.companyId === company.companyId) !== undefined;
    }));
    this.isUserCompanyConsumer$ = combineLatest([this.company$, consumerCompanies$]).pipe(map(([company, companies]) => {
      if (!company) { //no company context
        return false;
      }
      return companies.find(c => c.companyId === company.companyId) !== undefined;
    }));
    this._titleService.setTitle('OpsViewer Jobs');

    this.roles$ = this.user$.pipe(map(u => new UserRoles(u, this._userService)));

    this.applicationBarSubscription = combineLatest([this.isUserCompanyVendor$, this.isUserCompanyConsumer$]).subscribe(([isVendor, isConsumer]) => {
      if (!isVendor && !isConsumer) {
        this._applicationContextService.showApplicationRequestsBar();
      } else {
        this._applicationContextService.hideApplicationRequestsBar();
      }
    });
    
    this.setColumns();

    this.setSecurityToken();
  }
  setSecurityToken() {

    this._currentUserService.getAccessToken().subscribe(s => {
      //console.warn(s);
      this.securityToken = s;
    });

  }

  ngOnDestroy() {
    this._applicationContextService.hideApplicationRequestsBar();
    this.applicationBarSubscription.unsubscribe();
  }



  setColumns() {
    // TODO: Should Vendor Jobs show devices in a column?
    //       Should Consumer Jobs show Vendors in a column?
    this.vendorJobCols = [
      { field: 'name', header: 'Name', width: '160px', filterMatchMode: 'contains' },
      { field: 'apiNumber', header: 'Api Number', width: '160px', filterMatchMode: 'contains' },
      { field: 'wellInfo.name', header: 'Well Name', width: '180px', filterMatchMode: 'contains' },
      { field: 'consumerCompanyName', header: 'Operator', width: '180px', filterMatchMode: 'contains' },
      { field: 'startTime', header: 'Start', width: '120px' },
      { field: 'endTime', header: 'End', width: '120px' },
      { field: 'deviceNamesAsString', header: 'Devices', width: '180px', filterMatchMode: 'contains' },
      // tslint:disable-next-line:max-line-length
      // { field: 'devices', header: 'Devices', width: '150px', filterMatchMode: 'contains', alternativeFilterField: 'deviceNamesAsString' },
      { field: '', header: '', width: '175px' }
    ];

    this.consumerJobCols = [
      { field: 'number', header: 'Job Number', width: '160px', filterMatchMode: 'contains' },
      { field: 'wellApiNumber', header: 'Api Number', width: '160px', filterMatchMode: 'contains' },
      { field: 'wellName', header: 'Well Name', width: '200px', filterMatchMode: 'contains' },
      { field: 'vendorCompanyNames', header: 'Service Providers', width: '200px', filterMatchMode: 'contains' },
      { field: 'startTime', header: 'Start', width: '150px' },
      { field: 'endTime', header: 'End', width: '150px' },
      { field: '', header: '', width: '175px' }
    ];
  }

  navigateToAddVendorJob() {
    this._router.navigate(['/vendorjob/' + encodeURIComponent('new')]);
  }

  navigateToVendorDetail(jobId: string) {
    this._router.navigate(['/vendorjob/' + encodeURIComponent(jobId)]);
  }

  navigateToConsumerDetail(jobId: string) {
    this._router.navigate(['/consumerjob/' + encodeURIComponent(jobId)]);
  }

  navigateToVendorWorkbook(job: VendorJob) {
    if (job.useOldWorkbookInReadOnlyMode) {
      this._router.navigate(['/workbook/' + job.id]);
    } else {
      this._router.navigate(['/workbook2/' + job.id]);
    }
  }


  async navigateToVendorDashboard(jobId: string) {
    const link:string = await firstValueFrom(this._vendorJobService.getDashboardLink(jobId),{defaultValue:null});
    if (link) {
      window.open(link, '_blank');
    } else {
      this._telemetryService.trackTrace({message: 'No dashboard link found, service method ended without completion, for job id: ' + jobId});
      console.error('No dashboard link found for job id: ' + jobId);
    }
  }

  navigateToConsumerDashboard(job: ConsumerJob) {
    if (job.isMigratedFromBeforeConsumerJobs) {
      if (job.vendorJobIds && job.vendorJobIds.length > 0) {
        this._vendorJobService.getDashboardLink(job.vendorJobIds[0])
          .subscribe(l => {
            window.open(l, "_blank");
          });;
      } else {
        this._messageService.warnMessage('Cannot navigate to Consumer Job Dashboard', '');
      }
    } else {
      this._consumerJobService.getDashboardLink(job.id)
        .subscribe(l => {
          window.open(l, "_blank");
        });;
    }
  }

  downloadJobExport(jobId: string) {
    window.open('/api/vendorjob/export/' + jobId + '?token=' + this.securityToken, '_blank');
  }

  openFilePopUp(jobId: string) {
    this.ref = this._dialogService.open(FilePopUpComponent, {
      data: {
        jobId: jobId
      },
      header: 'Manage Files',
      width: '50vw',
      baseZIndex: 10000,
      resizable: true,
      maximizable: true
    });
  }

  downloadJobSummaryExport(jobId: string) {
    window.open('/api/vendorjob/exportsummary/' + jobId + '?token=' + this.securityToken, '_blank');
  }

  //#endregion

  //#region Deleting Jobs

  async confirmDeleteVendorJob(job: VendorJob): Promise<void> {
    const roles = await this.getUserRoles();
    if (roles.isGlobalAdmin()) {
      if (await this.doesJobHaveData(job.id)) {
        this.confirmDeleteVendorJobWithData(job);
      } else {
        this.confirmDeleteVendorJobWithoutData(job);
      }
    } else {
      this.confirmDeleteVendorJobWithoutData(job);
    }
  }

  confirmDeleteVendorJobWithoutData(job: VendorJob) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + job.name + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteVendorJob(job.id); }// ,
        // reject: () => { }
      }
    );
  }

  confirmDeleteVendorJobWithData(job: VendorJob) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'This job has data associated with it, are you sure you want to delete ' + job.name + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteVendorJob(job.id); }// ,
        // reject: () => { }
      }
    );
  }



  deleteVendorJob(jobId: string) {
    this.showSpinner();
    this._vendorJobService.deleteVendorJob(jobId).subscribe(
      result => {
        this.hideSpinner();
        //this.vendorJobs = this.vendorJobs.filter(j => j.id !== jobId);
        this.deletedId$.next([...this.deletedId$.value, jobId]);
        this._messageService.infoMessage('Service Provider Job deleted', '');
      },
      error => {
        this.hideSpinner();
        this._messageService.errorMessage(error, 'Unable to Delete Service Proivder Job');
      }
    );
  }

  async doesJobHaveData(jobId: string): Promise<boolean> {
    const result = firstValueFrom(this._vendorJobService.doesCompanyHaveData(jobId),{defaultValue:false});
    return result;
  }

  async getUserRoles(): Promise<UserRoles> {
    const user = await firstValueFrom(this.user$,{defaultValue:null});
    return new UserRoles(user, this._userService);
  }

  //#endregion

  showSpinner() {
    this._applicationContextService.showApplicationSpinner(true);
  }

  hideSpinner() {
    this._applicationContextService.hideApplicationSpinner();
  }
}
class UserRoles {
  constructor(private user:User, private userService:IUserService) {}
  isGlobalAdmin(): boolean {
    return this.user.isGlobalAdmin;
  }
  isEditorOrAdmin(jobCompanyId: string):boolean{
    return this.userService.isUserEditorOrAdmin(this.user, jobCompanyId, false);
  }
  isUserAdmin(jobCompanyId: string):boolean{
    return this.userService.isUserAdmin(this.user, jobCompanyId, false);
  }
}
