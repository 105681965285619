<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

<div class="import-container">
  <p-card>
    <ng-template pTemplate="header">
      <div class="flex justify-content-between align-items-center p-3">
        <h2 class="m-0">CSV Measurements Import</h2>
        <button pButton 
          icon="pi pi-arrow-left" 
          label="Back to Job" 
          class="p-button-outlined"
          (click)="navigateBack()">
        </button>
      </div>
    </ng-template>

    <div class="flex flex-column gap-3">
      <div *ngIf="loading" class="text-center p-5">
        <p>Loading job information...</p>
      </div>

      <div *ngIf="!loading && job" class="p-3 border-round">
        <h3 class="mt-0 mb-3">Job Information</h3>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="job-field mb-2">
              <label>Job Number:</label>
              <span>{{ job.name }}</span>
            </div>
            <div class="job-field mb-2">
              <label>Well Name:</label>
              <span>{{ job.wellInfo?.name || 'Not Specified' }}</span>
            </div>
            <div class="job-field mb-2">
              <label>API Number:</label>
              <span>{{ job.apiNumber || 'Not Specified' }}</span>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="job-field mb-2">
              <label>Start Date:</label>
              <span>{{ job.startTime | date:'medium' }}</span>
            </div>
            <div class="job-field mb-2">
              <label>End Date:</label>
              <span>{{ job.endTime ? (job.endTime | date:'medium') : 'Not Specified' }}</span>
            </div>
            <div class="job-field mb-2">
              <label>Job Type:</label>
              <span>{{ job.workbookType || 'Not Specified' }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Existing Imports Section -->
      <div *ngIf="!loading" class="p-3 border-round mb-3">
        <h3 class="mb-3">Existing Imports</h3>
        <div *ngIf="loadingImports" class="text-center p-3">
          <p>Loading imports...</p>
        </div>
        
        <div *ngIf="!loadingImports && imports.length === 0" class="p-3 text-center border-round">
          <p>No imports found for this job. Use the form below to add a new import.</p>
        </div>
        
        <p-table *ngIf="!loadingImports && imports.length > 0" 
                [value]="imports" 
                styleClass="p-datatable-sm"
                [tableStyle]="{'min-width': '50rem'}"
                [paginator]="imports.length > 10"
                [rows]="10"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} imports"
                [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="name">File Name <p-sortIcon field="name"></p-sortIcon></th>
              <th pSortableColumn="created">Upload Date <p-sortIcon field="created"></p-sortIcon></th>
              <th style="width: 8rem">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-import>
            <tr>
              <td>{{ import.name }}</td>
              <td>{{ getImportDate(import) }}</td>
              <td>
                <button pButton 
                        pRipple 
                        [icon]="deletingImportName === import.name ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" 
                        class="p-button-rounded p-button-danger p-button-text" 
                        pTooltip="Remove Import" 
                        tooltipPosition="top"
                        [disabled]="deletingImportName === import.name"
                        (click)="confirmRemoveImport(import)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <!-- Upload Form Section -->
      <div *ngIf="!loading" class="pt-3 border-top-1 border-gray-200">
        <h3 class="mb-3">Upload New Import</h3>
        <p class="mb-3 line-height-3 text-color-secondary">
          Upload a CSV file with measurements data for job: <strong>{{ job?.name || jobId }}</strong>. 
          The CSV file should have date in the first column (YYYY/MM/DD format),
          time in the second column (HH:MM:SS format), and measurements in subsequent columns.
        </p>

        <div class="time-offset-section p-3 mb-3 border-1 surface-border border-round">
          <h4 class="mt-0 mb-2">Time Zone Adjustment</h4>
          <p class="mb-2">
            The system uses UTC for date/time storage. If your CSV data is in local time, you can specify an offset (in hours) to convert to UTC time.
            Positive values move time forward, negative values move time backward.
          </p>
          <div class="grid align-items-center">
            <div class="col-12 md:col-3">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Hours Offset</span>
                <p-inputNumber [(ngModel)]="timeOffset" 
                              [showButtons]="false"
                              >
                </p-inputNumber>
              </div>
            </div>
            <div class="col-12 md:col-9">
              <span class="p-2 border-round">
                Example: <strong>12:00 PM</strong> in CSV + <strong>{{timeOffset}}</strong> hours offset = <strong>{{getExampleTimeWithOffset()}}</strong> UTC
              </span>
            </div>
          </div>
          <small class="block mt-2 ">
            <i class="pi pi-info-circle mr-1"></i>
            For example, if your data is in US Eastern Time (UTC-5), enter <strong>5</strong> as the offset.
          </small>
        </div>

        <div class="mb-3">
          <label class="block font-medium mb-2">Select CSV File</label>
          <p-fileUpload 
            #fileUpload
            mode="basic" 
            chooseLabel="Browse" 
            [auto]="false"
            accept=".csv" 
            [maxFileSize]="1000000000"
            (onSelect)="onFileSelect($event)" 
            [disabled]="uploading"
            styleClass="w-full">
          </p-fileUpload>
          
          <small *ngIf="fileName" class="selected-file text-color-secondary">
            Selected file: {{ fileName }}
          </small>
        </div>

        <div *ngIf="uploading" class="my-3">
          <p-progressBar [value]="progress"></p-progressBar>
          <small class="block text-center mt-2 text-color-secondary">Uploading: {{ progress }}%</small>
        </div>

        <div class="flex justify-content-end mt-3">
          <p-button 
            label="Upload" 
            icon="pi pi-upload" 
            (click)="onUpload()" 
            [disabled]="uploading || !selectedFile || !jobId"
            styleClass="p-button-primary">
          </p-button>
        </div>
      </div>
    </div>
  </p-card>
</div>
