import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { combineLatest, firstValueFrom, from, Observable, of } from 'rxjs';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { SelectItem } from 'primeng/api';
import {
         IOpsViewerMessagesService,
         ICompanyService,
         IJobWorkbookConfigurationService,
         IUserService,
         IDeviceService,
         IApplicationContextService } from 'src/app/services/services';
import { 
         JobWorkbookConfiguration,
         Company,
       
         WorkbookJobTypes,
         CompanyName,
         JobLinker} from 'src/app/models/models';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { getWorkbookTypesSelection } from 'src/app/models/workbookJobTypes';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { IVendorJobClient, VendorJobClient, VendorJob as VendorJob, WellInfo, FluidInfo, PerformanceSummary, FluidSummary, User, UserClient, CasingTubings, CasingTubingDetail, Device, DeviceClient, DeviceJobMapping } from 'src/app/services/nswag/nswagclient';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-vendor-job',
  
  standalone: false,
  templateUrl: './vendor-job.component.html',
  styleUrls: ['./vendor-job.component.css']
})
export class VendorJobComponent {

  //#region Component Properties
  // UI State properties
  modelInvalid = false;
  invalidCompanyId = false;
  showModelInvalidDialog = false;
  isNew = false;
  saving = false;
  // Job Properties
  job$: Observable<VendorJob>;
  jobToUpdate: VendorJob;
  // Company properties
  vendorCompanies$: Observable<SelectItem[]>;
  consumerCompanies$: Observable<SelectItem[]>;
  allCompanies$: Observable<SelectItem[]>;
  serviceProviderOptions$:  Observable<SelectItem[]>;
  company$: Observable<Company>;

  // Workbook properties
  jobTypes: SelectItem[] = [];
  jobWorkbookConfiguration: JobWorkbookConfiguration;
  jobTypeSet = false;

  // User properties
  user$: Observable<User>;
  companyContext$: Observable<CompanyName>;

  // Device properties
  devices$: Observable<Device[]>;
  usersCompanyDevices$: Observable<Device[]>;
  additionalServiceProvidersDevices$: Observable<Device[]>;

  userCompanyDeviceMaps$: Observable<DeviceJobMapping[]>;

  // Job Linker
  jobLinker: JobLinker = null;

  // Other UI properties

  securityToken$: Observable<string>;

  showCompanyContactInfoDialog = false;
  showManageFiles = false;

  //#endregion

  isUserServiceProvider: boolean;

  // Remove selectedDevice property since we're not using a dropdown anymore
  showDeviceDialog = false;

  // Change property name and default value
  showOnlyUnassigned = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject('IVendorJobClient') private _jobService: IVendorJobClient,
    private _companyService: ICompanyService,
    private _jobWorkbookConfigurationService: IJobWorkbookConfigurationService,
    private _userService: IUserService,
    private _deviceClient: DeviceClient,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService,
    private _titleService: Title,
    private _currentUserService: ICurrentUserService,
    private _vendorJobClient: VendorJobClient,
    userClient : UserClient,
    private _changeDetectorRef: ChangeDetectorRef
  ) { 
    this.consumerCompanies$ =  this._companyService.getConsumerCreatedAfterOrMigratedCompanyNames().pipe(map(c=>c.map(c=>{return {label:c.companyName, value:c.companyId}})),shareReplay(1));
    this.vendorCompanies$ = this._companyService.getVendorCompanyNames().pipe(map(c=>c.map(c=>{return {label:c.companyName, value:c.companyId}})),shareReplay(1));
    const vendorAndConsumerCompanies$ = combineLatest([this.vendorCompanies$,this.consumerCompanies$]);
    this.allCompanies$ = vendorAndConsumerCompanies$.pipe(map(([v,c])=>v.concat(c)),shareReplay(1));
    
    this.job$ = this._route.params.pipe(switchMap(params => {
      const jobId = params['id'];
      if (!jobId || jobId.toUpperCase() === 'NEW') {
        this.isNew = true;
        return from(this.createNewJob());
      } else {
        return this._jobService.getVendorJobById(jobId);
      }
    }), tap((job:VendorJob)=>this.setPageTitle(job.id)), tap((j:VendorJob)=>this.jobToUpdate = j), shareReplay(1));
    
    this.jobTypes = getWorkbookTypesSelection();
    this.securityToken$ = this._currentUserService.getAccessToken();

    this.companyContext$ =  this._applicationContextService.companyContextObserver;

    //const contextAndCompanies$ = combineLatest([this.companyContext$,this.allCompanies$]);
    const jobAndCompanies$ = combineLatest([this.job$,this.allCompanies$]);
    this.serviceProviderOptions$ = jobAndCompanies$.pipe(map(([job,companies])=>this.filterNotCompany(companies,job.companyId)),shareReplay(1));  //we don't want to be able to set the service provider to the same as the jobs company  
    this.company$ = this.companyContext$.pipe(switchMap(c=>this._companyService.getCompany(c.companyId)),shareReplay(1));

    let userEmail = this._currentUserService.getUserEmail();

    this.user$ = userClient.getByEmail(userEmail).pipe(shareReplay(1));
    this.devices$ = this.job$.pipe(switchMap(j=>this._deviceClient.getDevicesForCompany(j.companyId)),shareReplay(1));
    
    this.usersCompanyDevices$ = combineLatest([this.companyContext$, this.job$]).pipe(switchMap(([c,j])=> {
      if (c.companyId === j.companyId) return this.devices$;
      return this._deviceClient.getDevicesForCompany(c.companyId);
    }),map(d=>d.sort((a,b)=>a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase()))),shareReplay(1));
    
    this.additionalServiceProvidersDevices$ = combineLatest([this.companyContext$, this.job$]).pipe(switchMap(([c,j])=>{
      if (!j.serviceProviderIds || j.serviceProviderIds.length < 1) return of([]);
      const additionalCompanies = j.serviceProviderIds.filter(id=>id !== c.companyId);
      if (additionalCompanies.length < 1) return of([]);
      return combineLatest(additionalCompanies.map(id=>this._deviceClient.getDevicesForCompany(id))).pipe(map(d=>d.reduce((acc, curr) => acc.concat(curr), [])));
    }),shareReplay(1));

    this.userCompanyDeviceMaps$ = combineLatest([this.companyContext$, this.job$]).pipe(switchMap(([c,j])=>{
      const startTime = j.startTime ?? new Date();
      const endTime = j.endTime ?? new Date(2150, 1);
      return this._deviceClient.getDeviceJobMappingForCompany(c.companyId, startTime, endTime);
    }),shareReplay(1));

  }

  //#region *********** Initialization methods ************/
  

  async republishWorkbook(): Promise<void> {
    const job = await firstValueFrom(this.job$);
    try {
      await firstValueFrom(this._jobService.republish(job.id));
      this._messageService.infoMessage('published',
        `published workbook data for - ${job.name}`
       );
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not Republish Workbook Data');
    }
  }

  setPageTitle(jobId: string) {
    if (!jobId || jobId === 'New' || jobId === '') {
      this._titleService.setTitle('New Job');
    } else { this._titleService.setTitle(jobId); }
  }

  canLinkJob(job: VendorJob): boolean {
    if (this.isNew) return false;
    if (!job) return false;
    if (!job.wellInfo?.name) return false;
    if (!job.apiNumber) return false;
    return true;
  }


  //#endregion

  //#region *********** Getting or Creating new Job methods *******************/
 async createNewJob() : Promise<VendorJob> {
    const companyContext = await firstValueFrom(this.companyContext$);
    const newJob = new VendorJob();
    newJob.id = uuidv4().toUpperCase();
    newJob.startTime = new Date();
    newJob.companyId = companyContext.companyId;
    newJob.wellInfo = new WellInfo();
    newJob.wellInfo.casingTubings = new CasingTubings();
    newJob.wellInfo.casingTubings.casing1Vertical = new CasingTubingDetail();
    newJob.wellInfo.casingTubings.casing1Horizontal = new CasingTubingDetail();
    newJob.fluidInfo = new FluidInfo();
    newJob.performanceSummary = new PerformanceSummary();
    newJob.fluidSummary = new FluidSummary();
    return  newJob;
  }

  clearEndDate() {
    this.jobToUpdate.endTime = null; // Will not be updated in setDates if calendarEndTime is null
  }


  filterNotCompany( items: SelectItem[], companyId: string) {
    return items.filter(c => c.value !== companyId);
  }
  
  
  closeInvalidModelDialog() {
    this.showModelInvalidDialog = false;
  }

  async navigateToDashboard():Promise<void> {
    const job = await firstValueFrom(this.job$);
    const link = await firstValueFrom(this._jobService.getDashboardLink(job.id));
    window.open(link,"_blank");
  }

  async navigateToWorkbook() : Promise<void> {
    const job = await firstValueFrom(this.job$);
    if (job.useOldWorkbookInReadOnlyMode) {
      this._router.navigate(['/workbook/' + this.jobToUpdate.id]);
    } else {
      this._router.navigate(['/workbook2/' + this.jobToUpdate.id]);
    }
  }

  async downloadJobExport():Promise<void> {
    const job = await firstValueFrom(this.job$);
    const securityToken = await firstValueFrom(this.securityToken$);
    window.open('/api/vendorjob/export/' + job.id + '?token=' + securityToken, '_blank');
  }

  async downloadJobSummaryExport() :Promise<void> {
    const job = await firstValueFrom(this.job$);
    const securityToken = await firstValueFrom(this.securityToken$);
    window.open('/api/vendorjob/exportsummary/' + job.id + '?token=' + securityToken, '_blank');
  }

  async downloadSandReport() {
    const securityToken = await firstValueFrom(this.securityToken$);
    const beginTime = moment(this.jobToUpdate.startTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(this.jobToUpdate.startTime).add(1, 'y').format('YYYY-MM-DDTHH:mm:ss');
    window.open('/api/reports/sand/' +
    this.jobToUpdate.id +
    '?beginTime=' + beginTime +
    '&endTime=' + endTime +
    '&token=' + securityToken, '_blank');
  }

  async downloadDataSheetReport() {
    const securityToken = await firstValueFrom(this.securityToken$);
    const beginTime = moment(this.jobToUpdate.startTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(this.jobToUpdate.startTime).add(1, 'y').format('YYYY-MM-DDTHH:mm:ss');
    window.open('/api/reports/datasheet/' +
    this.jobToUpdate.id +
    '?beginTime=' + beginTime +
    '&endTime=' + endTime +
    '&token=' + securityToken, '_blank');
  }

  async saveJob() : Promise<void> {
    const isModelValid = await this.validateJobModel();
    this.showModelInvalidDialog = this.modelInvalid = !isModelValid;

    if (!this.showModelInvalidDialog) {
      this._applicationContextService.showApplicationSpinner(false);
      if (this.isNew) {
        try {
        const result = await firstValueFrom(this._vendorJobClient.createVendorJob(new VendorJob(<any>this.jobToUpdate)));
        
          await this.saveJobConfiguration();
          // In case this is the first consumer job with this consumer company
          // the company list in the user profile needs to update
          this._applicationContextService.alertCompaniesListUpdated();
          this._applicationContextService.hideApplicationSpinner();
        } catch (error) {
          this._applicationContextService.hideApplicationSpinner();
          this._messageService.errorMessageWithObjectDetail(error, 'Could not create job', JSON.stringify(this.jobToUpdate));
          return;
        }
      
      } else {
        try {
          const result = await firstValueFrom( this._jobService.put(this.jobToUpdate.id, this.jobToUpdate));
          await this.saveJobConfiguration();
          this._applicationContextService.hideApplicationSpinner();
        } catch (error) {
          this._applicationContextService.hideApplicationSpinner();
          this._messageService.errorMessageWithObjectDetail(error, 'Could not update job', JSON.stringify(this.jobToUpdate));
          return;
        }
        
      }
      this._router.navigate(['/jobs2/']);
    }
  }
  returnToJobList() {
    this._router.navigate(['/jobs2/']);
  }


  showContactInfo() {
    this.showCompanyContactInfoDialog = true;
  }


  //#region *********** Workbook Configuration Methods ********/
  

  updateWorkbookConfiguration(event: any) {
    this.jobWorkbookConfiguration = event;
  }

  handleErrorsFromConfiguration(event: any) {
    this._messageService.errorMessage(event, 'Error with Job Workbook Configuration');
  }

  async saveJobConfiguration() : Promise<void> {
    try {
     const result = await firstValueFrom(this._jobWorkbookConfigurationService.addOrUpdateJobWorkbookConfiguration(this.jobWorkbookConfiguration))
    } catch (error) {
      error => { this._messageService.errorMessage(error, 'Could not save Job Workbook Configuration'); }
    }
  }


 //#region *********** Other Helper methods ***********/
  isAdmin(user:User, companyId:string): boolean {
    return this._userService.isUserAdmin(user, companyId, false);
  }

  isEditorOrAdmin(user:User, companyId:string): boolean {
    return this._userService.isUserEditorOrAdmin(user, companyId, false);
  }



  async validateJobModel(): Promise<boolean> {
    let valid = true;
    this.invalidCompanyId = false;
    if (!this.jobToUpdate.name) { valid = false; }
    if (!this.jobToUpdate.companyId) { valid = false; }
    const vendorCompanies = await firstValueFrom(this.vendorCompanies$);
    if (this.jobToUpdate.companyId && !vendorCompanies.find(v => v.value === this.jobToUpdate.companyId)) {
      this.invalidCompanyId = true;
      valid = false;
    }
    if (!this.jobToUpdate.consumerCompanyId) { valid = false; }
    // TODO: Does at least one device need to be associated?  If so uncomment
    //      Also uncomment HTML validation message
    // if(!this.job.deviceList || this.job.deviceList.length < 1) valid = false;
    if (!this.jobToUpdate.startTime) { valid = false; }
    if (!this.jobToUpdate.apiNumber && !this.jobToUpdate.wellInfo.name) { valid = false; }
    // if(!this.job.endTime) valid = false;
    if (!this.jobToUpdate.workbookType) { valid = false; }
    if ((this.jobToUpdate.workbookType === WorkbookJobTypes.flowbackWellTesting) && !this.jobToUpdate.wellInfo.wellType) {
      valid = false;
    }
    if (this.jobToUpdate.enableOfflineWorkbook && !this.jobToUpdate.workbookType) { valid = false; }

    if ((this.jobToUpdate.workbookType === WorkbookJobTypes.flowbackWellTesting) &&
        (!this.jobToUpdate.frcsDayEngineer || !this.jobToUpdate.dayOperators ||
         !this.jobToUpdate.frcsNightEngineer || ! this.jobToUpdate.nightOperators)) {
           valid = false;
         }

    if ((!this.jobWorkbookConfiguration) || this.jobWorkbookConfiguration.workbookMeasurementChannelConfigurations.length <1){
      valid = false;
      this._messageService.errorMessageWithObjectDetail("invalid workbook configuration", 'Could not update job', JSON.stringify(this.jobToUpdate));
    }
    if (this.jobToUpdate.fluidInfo.density != Number(this.jobToUpdate.fluidInfo.density)){
      this._messageService.errorMessageWithObjectDetail("invalid fluild density", 'Could not update job', JSON.stringify(this.jobToUpdate));
      valid = false;
    }

    if (this.jobToUpdate.wellInfo && this.jobToUpdate.wellInfo.kop && this.jobToUpdate.wellInfo.kop != Number(this.jobToUpdate.wellInfo.kop)){
      this._messageService.errorMessageWithObjectDetail("invalid kop", 'Could not update job', JSON.stringify(this.jobToUpdate));
      valid = false;
    }

    if (this.jobToUpdate.wellInfo && this.jobToUpdate.wellInfo.sicp && this.jobToUpdate.wellInfo.sicp != Number(this.jobToUpdate.wellInfo.sicp)){
      this._messageService.errorMessageWithObjectDetail("invalid scip", 'Could not update job', JSON.stringify(this.jobToUpdate));
      valid = false;
    }
    return valid;
  }
 
  getMappedDeviceList(job:VendorJob): Observable<Device[]> {
    return combineLatest([this.devices$, this.usersCompanyDevices$, this.additionalServiceProvidersDevices$]).pipe(map(([jobCompany, user, additional])=>{
      if (!job.deviceList || job.deviceList.length < 1) return [];
      const all = jobCompany.concat(user).concat(additional);
      //console.log('alldevices', all);
      const results = [];
      for(const id of job.deviceList) {
        const device = all.find(d=>d.id === id);
        if (device)  {
          results.push(device);
        } else {
          console.log('device not found', id);
        }
      }
      return results;
    }));
  }

  // Update addDevice method to take deviceId parameter
  addDevice(deviceId: string) {
    if (!this.jobToUpdate.deviceList) {
      this.jobToUpdate.deviceList =[deviceId];
    } else if (!this.jobToUpdate.deviceList?.includes(deviceId)) {
      // Create a new array reference to trigger change detection
      this.jobToUpdate.deviceList = [...this.jobToUpdate.deviceList, deviceId];
    }
    this.showDeviceDialog = false; // Close dialog after assigning
  }

  removeDevice(deviceId: string) {
    this.jobToUpdate.deviceList = this.jobToUpdate.deviceList.filter(id => id !== deviceId);
  }

  // Update filter method
  filterDevices(devices: DeviceJobMapping[]): DeviceJobMapping[] {
    if (!this.showOnlyUnassigned) {
      return devices;
    }
    return devices.filter(d => !d.jobId);
  }

  async navigateToCsvImport(): Promise<void> {
    const job = await firstValueFrom(this.job$);
    this._router.navigate(['/csvimport', job.id]);
  }

  //#endregion
}
