import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FileUploadModule, FileUpload } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { ImportClient, FileParameter, VendorJobClient, VendorJob, CsvJobImport } from '../../../services/nswag/nswagclient';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-csvimport',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FileUploadModule,
    ButtonModule,
    ProgressBarModule,
    ToastModule,
    CardModule,
    TableModule,
    TooltipModule,
    ConfirmDialogModule,
    InputNumberModule
  ],
  providers: [MessageService, ConfirmationService, ImportClient, VendorJobClient],
  templateUrl: './csvimport.component.html',
  styleUrl: './csvimport.component.scss'
})
export class CsvimportComponent implements OnInit, OnDestroy {
  
  @ViewChild('fileUpload') fileUpload: FileUpload;
  
  selectedFile: File | null = null;
  fileName: string = '';
  uploading: boolean = false;
  progress: number = 0;
  jobId: string = '';
  job: VendorJob | null = null;
  loading: boolean = true;
  imports: CsvJobImport[] = [];
  loadingImports: boolean = false;
  timeOffset: number = 6; // Default to 6 hours offset
  deletingImportName: string | null = null; // Track which import is being deleted
  private progressInterval: any; // For tracking the progress simulation timer
  
  constructor(
    private importClient: ImportClient,
    private vendorJobClient: VendorJobClient,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const jobId = params.get('jobId');
      if (jobId) {
        this.jobId = jobId;
        this.loadJobDetails(jobId);
        this.loadImports(jobId);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Invalid Job ID',
          detail: 'No job ID was provided in the route.'
        });
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.clearProgressSimulation();
  }

  loadJobDetails(jobId: string): void {
    this.loading = true;
    this.vendorJobClient.getVendorJobById(jobId)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe({
        next: (job) => {
          this.job = job;
        },
        error: (error) => {
          console.error('Error loading job details:', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Error Loading Job',
            detail: 'Could not load job details.'
          });
        }
      });
  }

  loadImports(jobId: string): void {
    this.loadingImports = true;
    this.importClient.getImportsForJob(jobId)
      .pipe(
        finalize(() => this.loadingImports = false)
      )
      .subscribe({
        next: (imports) => {
          this.imports = imports;
        },
        error: (error) => {
          console.error('Error loading imports:', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Error Loading Imports',
            detail: 'Could not load existing imports.'
          });
        }
      });
  }

  onFileSelect(event: any): void {
    const files = event.files;
    if (files && files.length > 0) {
      this.selectedFile = files[0];
      this.fileName = this.selectedFile.name;
    }
  }

  onUpload(): void {
    if (!this.selectedFile) {
      this.messageService.add({
        severity: 'error',
        summary: 'No File Selected',
        detail: 'Please select a CSV file to upload.'
      });
      return;
    }

    if (!this.jobId) {
      this.messageService.add({
        severity: 'error',
        summary: 'Missing Job ID',
        detail: 'No job ID is available for this import.'
      });
      return;
    }

    this.uploading = true;
    this.progress = 0;
    
    // Start progress simulation
    this.startProgressSimulation();

    // Create a FileParameter object as required by the ImportClient
    const fileParam: FileParameter = {
      data: this.selectedFile,
      fileName: this.selectedFile.name
    };

    // Pass the time offset as a parameter to the import endpoint
    this.importClient.importCsv(fileParam, this.jobId, this.timeOffset).subscribe({
      next: () => {
        this.clearProgressSimulation();
        this.uploading = false;
        this.progress = 100; // Set to 100% once complete
        
        this.messageService.add({
          severity: 'success',
          summary: 'Upload Complete',
          detail: 'CSV file has been successfully uploaded and processed.'
        });
        
        this.resetForm();
        this.loadImports(this.jobId); // Refresh the imports list
      },
      error: (error) => {
        this.clearProgressSimulation();
        this.uploading = false;
        console.error('Upload error:', error);
        
        let errorMessage = 'An error occurred during upload.';
        if (error.error && typeof error.error === 'string') {
          errorMessage = error.error;
        } else if (error.message) {
          errorMessage = error.message;
        }
        
        this.messageService.add({
          severity: 'error',
          summary: 'Upload Failed',
          detail: errorMessage
        });
      }
    });
  }

  startProgressSimulation(): void {
    this.clearProgressSimulation(); // Clear any existing interval
    
    // Calculate steps based on expected 2-minute upload time
    // Update every 3 seconds and cap at 95% until server confirms completion
    this.progressInterval = setInterval(() => {
      if (this.progress < 95) {
        // Gradually slow down progress as it gets higher
        const increment = this.progress < 50 ? 5 : 
                          this.progress < 70 ? 3 : 
                          this.progress < 85 ? 2 : 1;
        
        this.progress = Math.min(95, this.progress + increment);
      }
    }, 3000);
  }

  clearProgressSimulation(): void {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
  }

  resetForm(): void {
    this.clearProgressSimulation();
    this.selectedFile = null;
    this.fileName = '';
    this.progress = 0;
    
    // Reset the file upload component
    if (this.fileUpload) {
      this.fileUpload.clear();
    }
    // We don't reset timeOffset as the user likely wants to keep this value
  }

  navigateBack(): void {
    if (this.job) {
      this.router.navigate(['/vendorjob', this.jobId]);
    } else {
      this.router.navigate(['/jobs2']);
    }
  }
  
  confirmRemoveImport(importItem: CsvJobImport): void {
    this.confirmationService.confirm({
      message: `Are you sure you want to remove the import "${importItem.name}"? This action cannot be undone.`,
      header: 'Confirm Removal',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removeImport(importItem);
      }
    });
  }
  
  removeImport(importItem: CsvJobImport): void {
    if (!importItem || !importItem.name || !this.jobId) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Missing import information.'
      });
      return;
    }
    
    this.deletingImportName = importItem.name; // Set the currently deleting import
    
    this.importClient.removeImport(this.jobId, importItem.name).subscribe({
      next: () => {
        this.deletingImportName = null; // Clear deleting state
        this.messageService.add({
          severity: 'success',
          summary: 'Import Removed',
          detail: `The import "${importItem.name}" has been successfully removed.`
        });
        this.loadImports(this.jobId); // Refresh the list
      },
      error: (error) => {
        this.deletingImportName = null; // Clear deleting state
        console.error('Error removing import:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Removal Failed',
          detail: 'An error occurred while removing the import.'
        });
      }
    });
  }
  
  getImportDate(importItem: CsvJobImport): string {
    return importItem.created ? new Date(importItem.created).toLocaleString() : 'Unknown';
  }

  getExampleTimeWithOffset(): string {
    // Use noon as an example time
    const exampleDate = new Date();
    exampleDate.setHours(12, 0, 0, 0); // Set to 12:00:00.000
    
    // Apply the offset
    const offsetTime = new Date(exampleDate.getTime() + (this.timeOffset * 60 * 60 * 1000));
    
    // Format the time
    return offsetTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
}
