<div *ngIf="job != null && job.fluidInfo != null" class="grid field col-12">
  <div class="grid field col-12">
      <!-- Chlorides -->
      <div class="col-12 md:col-1">
          <label>Chlorides (ppm)</label>
      </div>
      <div class="col-12 md:col-3">
          <p-inputNumber placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.chlorides" mode="decimal" [maxFractionDigits]="4" [disabled]="disabled"/>
      </div>
      <!-- NaCL -->
      <div class="col-12 md:col-1">
          <label>NaCL/Brine (%w/v)</label>
      </div>
      <div class="col-12 md:col-3">
          <p-inputNumber placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.naCl" mode="decimal" [maxFractionDigits]="4" [disabled]="disabled"/>
      </div>
      <!-- Fluid Density -->
      <div class="col-12 md:col-1">
          <label>Fluid Density (lbs/gal)</label>
      </div>
      <div class="col-12 md:col-3">
          <p-inputNumber placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.density" mode="decimal" [maxFractionDigits]="4" [disabled]="disabled"/>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- Hardness -->
      <div class="col-12 md:col-1">
          <label>Hardness (ppm)</label>
      </div>
      <div class="col-12 md:col-3">
          <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.hardness" [disabled]="disabled"/>
      </div>
      <!-- Iron (ppm) -->
      <div class="col-12 md:col-1">
          <label>Iron (ppm)</label>
      </div>
      <div class="col-12 md:col-3">
          <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.iron" [disabled]="disabled"/>
      </div>
      <!-- Sulfates -->
      <div class="col-12 md:col-1">
          <label>Sulfates (ppm)</label>
      </div>
      <div class="col-12 md:col-3">
          <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.sulfates" [disabled]="disabled"/>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- Alkalinity -->
      <div class="col-12 md:col-1">
          <label>Alkalinity (ppm)</label>
      </div>
      <div class="col-12 md:col-3">
          <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.alkalinitty" [disabled]="disabled"/>
      </div>
      <!-- pH -->
      <div class="col-12 md:col-1">
          <label>pH</label>
      </div>
      <div class="col-12 md:col-3">
          <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.fluidInfo.ph" [disabled]="disabled"/>
      </div>
  </div>
</div>
